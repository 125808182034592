import { createHttpEndpoint } from '../utils'

import type { RatingDistribution } from './types/distributions'
import type { GetReviewsListResponse } from './types/list'
import type { Rating } from './types/rating'
import type { Review } from './types/review'

/**
 * Specific review for a specific product
 * The review be translated using the query parameter locale
 *
 * @see {@link https://devportal.backmarket.io/catalog/default/api/review-service-api/definition#tag/product/operation/get-reviews-review-id}
 */
export const getReview = createHttpEndpoint<Review>({
  method: 'GET',
  operationId: 'fetchReviewById',
  path: '/reviews/products/reviews/:reviewId',
})

/**
 * Specific review for a specific product
 * The review will be translated using the query parameter translation_locale
 *
 * @see {@link https://devportal.backmarket.io/catalog/default/api/review-service-api/definition#/product/get-reviews-review-id-v2}
 */
export const getReviewV2 = createHttpEndpoint<Review>({
  method: 'GET',
  operationId: 'fetchReviewByIdV2',
  path: '/reviews/v2/products/reviews/:reviewId',
})

/**
 * List of customer reviews
 *
 * @see {@link https://devportal.backmarket.io/catalog/default/api/review-service-api/definition#tag/product/operation/get-reviews-products-reviews}
 */
export const getReviews = createHttpEndpoint<GetReviewsListResponse>({
  method: 'GET',
  operationId: 'fetchReviewsList',
  path: '/reviews/v1/products/reviews',
})

/**
 * Review summary (average rate & number of reviews)
 *
 * @see {@link https://devportal.backmarket.io/catalog/default/api/review-service-api/definition#tag/product/operation/get-reviews-products-reviews-summary}
 */
export const getRating = createHttpEndpoint<Rating>({
  method: 'GET',
  operationId: 'fetchRatingSummary',
  path: '/reviews/products/reviews-summary',
})

/**
 * Distribution of ratings
 *
 * @see {@link https://devportal.backmarket.io/catalog/default/api/review-service-api/definition#tag/product/operation/get-reviews-products-distributions}
 */
export const getRatingDistribution = createHttpEndpoint<RatingDistribution[]>({
  method: 'GET',
  operationId: 'fetchRatingDistribution',
  path: '/reviews/products/reviews/distributions',
})
