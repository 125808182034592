<template>
  <RevDrawer
    :close-button-label="i18n(translations.alternativeClose)"
    :large="true"
    :name="name"
    :title="currentReview.product.title"
  >
    <template #trigger="{ open }">
      <slot name="trigger" :open="open" />
    </template>

    <template #body>
      <div
        class="grid"
        :class="[
          $style.gridLayout,
          reviewsIds && reviewsIds?.length > 1 ? $style.gridWithController : '',
        ]"
      >
        <div v-if="currentPictures.length === 1" :class="$style.gridPictures">
          <RevIllustration
            alt=""
            class="rounded-lg !max-h-[80vh] object-cover"
            :height="480"
            loading="eager"
            sizes="(min-width: 768px) 448px, 328px"
            :src="currentPictures[0]"
            :width="448"
          />
        </div>

        <div v-else :class="$style.gridPictures">
          <div class="relative">
            <RevCarousel
              :key="currentReview.id"
              :alternative-button-label="
                i18n(translations.alternativeController)
              "
              :alternative-next="i18n(translations.alternativeNext)"
              :alternative-previous="i18n(translations.alternativePrevious)"
              class="h-full w-full"
              :close-button-label="i18n(translations.alternativeClose)"
              current-index-id="gallery-media-viewer"
              name="review-media-viewer"
              :thumbnails
            >
              <RevIllustration
                v-for="(image, index) in currentPictures"
                :key="image"
                alt=""
                class="rounded-lg !max-h-[60vh] object-cover"
                data-test="user-comment-photos-slider-picture"
                :height="480"
                :loading="shouldLoadEagerly(index)"
                sizes="(min-width: 768px) 448px, 328px"
                :src="image"
                :width="448"
              />
            </RevCarousel>
          </div>
        </div>

        <RevDivider class="my-16 md:hidden" :class="$style.gridDivider" />

        <div
          v-if="reviewsIds && reviewsIds.length > 1"
          :class="$style.gridController"
        >
          <ReviewsController
            :current-review-id="currentReview.id"
            data-test="reviews-controller"
            :reviews-ids="reviewsIds"
            @switch="switchToReview"
          />
        </div>

        <div :class="$style.gridComment">
          <UserCommentView
            v-bind="currentReview"
            :displayProductLink="displayProductLink"
            :track-review-impression="trackReviewImpression"
            :tracking-data="trackingData"
          />
        </div>
      </div>
    </template>
  </RevDrawer>
</template>

<script lang="ts" setup>
import { useId, useRoute } from '#imports'
import { computed, ref } from 'vue'

import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { useTracking } from '@backmarket/nuxt-module-tracking/useTracking'
import { RevCarousel } from '@ds/components/Carousel'
import { RevDivider } from '@ds/components/Divider'
import { RevDrawer } from '@ds/components/Drawer'
import { RevIllustration } from '@ds/components/Illustration'

import UserCommentView, {
  type UserComment,
} from '../UserCommentView/UserCommentView.vue'

import translations from './UserComment.translations'
import ReviewsController from './components/ReviewsController.vue'

const { trackClick } = useTracking()
const route = useRoute()
const i18n = useI18n()
const name = useId()

const props = withDefaults(
  defineProps<{
    pictures: Array<string>
    review: UserComment
    reviews?: (UserComment & { pictures: Array<string> })[]
    trackReviewImpression?: boolean
    displayProductLink?: boolean
  }>(),
  {
    reviews: undefined,
    trackReviewImpression: false,
    displayProductLink: true,
  },
)

const currentReview = ref(props.review)
const currentPictures = ref(props.pictures)

const reviewsIds = computed(() => props.reviews?.map((r) => r.id))

const trackingData = computed(() => {
  // Tracks only for modals opened from the reviews carousel
  if (reviewsIds.value) {
    return {
      onClick: {},
      onImpression: {
        zone: 'carousel',
        photosReviewsDisplayed: currentPictures.value.length,
        reviewPhotos: true,
        pageType: route.name as string,
      },
    }
  }

  return undefined
})

function switchToReview(reviewId: string | null) {
  if (!props.reviews || !reviewId) return

  const newReview = props.reviews.find((review) => review.id === reviewId)

  if (newReview) {
    currentReview.value = newReview
    currentPictures.value = newReview.pictures

    trackClick({
      zone: 'reviews_controller',
      page_type: route.name as string,
    })
  }
}

function shouldLoadEagerly(index: number) {
  return index === 0 ? 'eager' : 'lazy'
}

const thumbnails = computed(() => {
  return currentPictures.value.map((pictureUrl) => ({
    src: pictureUrl,
    width: 100,
    height: 100,
    alt: '',
  }))
})
</script>

<style module>
.gridLayout {
  grid-template-columns: minmax(0, 1fr);
  grid-template-areas:
    'pictures'
    'divider'
    'comment';
  gap: 16px;
}

.gridWithController {
  grid-template-areas:
    'controller'
    'pictures'
    'divider'
    'comment';
}

@media only screen and (min-width: 768px) {
  .gridLayout {
    grid-template-columns: 1fr 1fr;
    grid-template-areas: 'pictures comment';
    gap: 24px;
  }

  .gridWithController {
    grid-template-rows: 56px auto;
    grid-template-areas:
      'pictures controller'
      'pictures comment';
  }
}

.gridPictures {
  grid-area: pictures;
}

.gridController {
  grid-area: controller;
}

.gridComment {
  grid-area: comment;
}

.gridDivider {
  grid-area: divider;
}
</style>
