<template>
  <div
    class="bg-static-default-hi rounded-md flex items-center justify-between p-12"
  >
    <RevButtonRounded
      :alternative-text="i18n(translations.reviewsControllerPreviousReview)"
      :aria-label="i18n(translations.reviewsControllerPreviousReview)"
      :disabled="!previousReviewId"
      :icon="IconChevronLeft"
      size="small"
      variant="primary"
      @click="emit('switch', previousReviewId)"
    />

    <p class="text-static-default-mid body-2">{{ title }}</p>

    <RevButtonRounded
      :alternative-text="i18n(translations.reviewsControllerNextReview)"
      :aria-label="i18n(translations.reviewsControllerNextReview)"
      :disabled="!nextReviewId"
      :icon="IconChevronRight"
      size="small"
      variant="primary"
      @click="emit('switch', nextReviewId)"
    />
  </div>
</template>

<script lang="ts" setup>
import { computed } from 'vue'

import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { RevButtonRounded } from '@ds/components/ButtonRounded'
import { IconChevronLeft } from '@ds/icons/IconChevronLeft'
import { IconChevronRight } from '@ds/icons/IconChevronRight'

import translations from './ReviewsController.translations'

const i18n = useI18n()
const emit = defineEmits(['switch'])

const props = defineProps<{
  currentReviewId: string
  reviewsIds: string[]
}>()

const currentReviewIndex = computed(() => {
  return props.reviewsIds.findIndex(
    (reviewId) => reviewId === props.currentReviewId,
  )
})

const previousReviewId = computed(() => {
  if (currentReviewIndex.value === -1) return null

  if (currentReviewIndex.value === 0)
    return props.reviewsIds[props.reviewsIds.length - 1]

  return props.reviewsIds[currentReviewIndex.value - 1]
})

const nextReviewId = computed(() => {
  if (currentReviewIndex.value === -1) return null

  if (currentReviewIndex.value === props.reviewsIds.length - 1)
    return props.reviewsIds[0]

  return props.reviewsIds[currentReviewIndex.value + 1]
})

const title = computed(() => {
  return i18n(translations.reviewsControllerTitle, {
    current: currentReviewIndex.value + 1,
    max: props.reviewsIds.length,
  })
})
</script>
