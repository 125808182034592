<template>
  <div ref="reviewObserverTarget">
    <div
      class="flex flex-col flex-wrap items-start justify-between gap-32"
      data-qa="user-comment"
    >
      <!-- Customer info -->
      <div class="flex items-start">
        <RevAvatar
          class="mr-12 shrink-0"
          :class="avatarBackground"
          :label="avatarLabel"
          size="medium"
          :thumbnail="authIconAvatar"
        />

        <div class="flex-1">
          <p class="body-1-bold">
            {{ userName }}
          </p>

          <p
            v-if="formattedPurchaseDate"
            class="text-static-default-low body-2"
          >
            {{ formattedPurchaseDate }}
          </p>
        </div>
      </div>

      <!-- Handled with care block -->
      <div
        v-if="!!originalReview"
        class="bg-static-info-mid text-static-default-hi rounded-lg flex w-full grow p-16 md:order-2"
      >
        <IconHeadset class="mr-12 shrink-0" />

        <FormattedMessage :definition="translations.handledWithCare" tag="p">
          <template #text>
            <span class="body-1-bold">
              {{ i18n(translations.handledWithCareInnerText) }}
            </span>
          </template>
        </FormattedMessage>
      </div>

      <RevRating :score="averageRate" size="small" />
    </div>

    <!-- PhotosSlider (or any other component) can be injected here-->
    <slot />

    <ReviewText
      v-if="editedReviewIsDifferentFromOriginal"
      :comment="mainReviewComment"
      data-test="main-review-comment"
      :force-expand="expandComment"
      :tracking="trackingData.onClick"
    />
    <!-- Translation -->
    <div
      v-if="isTranslatable"
      class="mt-24 flex flex-col flex-wrap items-start md:mt-32 md:flex-row md:items-center"
    >
      <div v-if="isTranslated" class="mb-8 mr-4 flex items-center md:mb-0">
        <IconSparkles class="mr-4" />

        {{ translatedFrom }}

        <span class="ml-4 hidden md:block"> - </span>
      </div>

      <RevLink
        data-test="toggle-translation-button"
        @click="onToggleTranslation"
      >
        {{ toggleTranslationLabel }}
      </RevLink>
    </div>

    <!-- Comment or update date -->
    <p class="text-static-default-low body-2 mt-32">
      {{ commentDate }}
    </p>

    <!-- Original review -->
    <div
      v-if="originalReview"
      class="border-static-default-low text-static-default-low mt-32 border-l-1 pl-24"
    >
      <p class="body-1-bold mb-8">{{ i18n(translations.originalReview) }}</p>

      <RevRating
        class="!text-static-default-low"
        :score="originalReview.averageRate"
        size="small"
      />

      <ReviewText
        :comment="originalReview.comment"
        data-test="original-review-comment"
        :tracking="trackingData.onClick"
      />

      <p class="body-2 mt-32">
        {{
          i18n(translations.reviewDate, {
            country: i18n.country(originalReview.countryCode),
            date: useFormatDateToLongDate(originalReview.createdAt),
          })
        }}
      </p>
    </div>

    <!-- Product link -->
    <template v-if="displayProductLink && product.title">
      <RevButtonBase
        v-if="productRouteExists"
        class="mt-32 block"
        rel="nofollow"
        :to="productLink"
      >
        <RevTag :label="product.title" variant="secondary" />
      </RevButtonBase>

      <RevTag v-else :label="product.title" variant="secondary" />
    </template>
  </div>
</template>

<script setup lang="ts">
import { useRouter } from '#imports'
import { computed, ref, toRef, watch } from 'vue'

import type { Link, LinkInternal } from '@backmarket/http-api'
import FormattedMessage from '@backmarket/nuxt-module-i18n/FormattedMessage.vue'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { useMarketplace } from '@backmarket/nuxt-module-marketplace/useMarketplace'
import { toNuxtLink } from '@backmarket/nuxt-module-navigation/toNuxtLink'
import { useTracking } from '@backmarket/nuxt-module-tracking/useTracking'
import { toBcp47Locale } from '@backmarket/utils/string/toBcp47Locale'
import { RevAvatar } from '@ds/components/Avatar'
import { RevButtonBase } from '@ds/components/ButtonBase'
import { RevLink } from '@ds/components/Link'
import { RevRating } from '@ds/components/Rating'
import { RevTag } from '@ds/components/Tag'
import { IconHeadset } from '@ds/icons/IconHeadset'
import { IconSparkles } from '@ds/icons/IconSparkles'

import ReviewText from './ReviewText.vue'
import translations from './UserComment.translations'
import { useAvatar } from './useAvatar'
import { useTrackReviewImpression } from './useTrackReviewImpression'
import { useTranslateComment } from './useTranslateComment'

export type UserComment = {
  id: string
  comment: string
  averageRate: number
  originalReview?: {
    averageRate: number
    comment: string
    countryCode: string
    createdAt: string
  }
  translatedComment?: string
  customer: { firstName?: string; lastName?: string }
  countryCode: string
  reviewDate: string
  purchaseDate: string
  handledWithCare?: boolean
  product: {
    title: string
    link: Link | string
  }
  languageCode: string
}
export type UserCommentProps = UserComment & {
  trackingData?: {
    onClick: Record<string, unknown>
    onImpression: Record<string, unknown>
  }
  isHighlighted?: boolean
  trackReviewImpression?: boolean
}

const props = withDefaults(
  defineProps<
    UserComment & {
      trackingData?: {
        onClick: Record<string, unknown>
        onImpression: Record<string, unknown>
      }
      isHighlighted?: boolean
      trackReviewImpression?: boolean
      displayProductLink?: boolean
    }
  >(),
  {
    trackingData: () => ({
      onClick: {},
      onImpression: {},
    }),
    originalReview: undefined,
    translatedComment: undefined,
    trackReviewImpression: false,
    displayProductLink: true,
  },
)

const router = useRouter()

const productRouteExists = computed(() => {
  try {
    return router.resolve(toNuxtLink(props.product.link)).matched.length > 0
  } catch {
    return false
  }
})

const i18n = useI18n()

const userName = computed(() => {
  if (props.customer.firstName && props.customer.lastName) {
    return `${props.customer.firstName} ${props.customer.lastName}`
  }

  return (
    props.customer.firstName ??
    props.customer.lastName ??
    i18n(translations.anonymousUser)
  )
})

const emit = defineEmits<{
  (e: 'toggle-translation'): void
}>()

const {
  mainReviewComment,
  isTranslated,
  toggleTranslation,
  isTranslatable,
  toggleTranslationLabel,
  translatedFrom,
  editedReviewIsDifferentFromOriginal,
} = useTranslateComment(props)
const expandComment = ref(false)

watch(
  () => props.translatedComment,
  (newTranslatedComment) => {
    if (newTranslatedComment) {
      expandComment.value = true
    }
  },
)

const { trackClick } = useTracking()
async function onToggleTranslation() {
  trackClick({
    name: isTranslated.value ? 'see_original' : 'translate',
    ...props.trackingData?.onClick,
  })
  toggleTranslation()

  emit('toggle-translation')
}

const useFormatDateToLongDate = (date: string) =>
  i18n.date(new Date(date), {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  })

const formattedPurchaseDate = computed(() =>
  props.purchaseDate
    ? i18n(translations.purchaseDate, {
        date: useFormatDateToLongDate(props.purchaseDate),
      })
    : '',
)

const commentDate = computed(() => {
  return props.originalReview
    ? i18n(translations.reviewUpdateDate, {
        country: i18n.country(props.countryCode),
        date: useFormatDateToLongDate(props.reviewDate),
      })
    : i18n(translations.reviewDate, {
        country: i18n.country(props.countryCode),
        date: useFormatDateToLongDate(props.reviewDate),
      })
})

const {
  label: avatarLabel,
  thumbnail: authIconAvatar,
  backgroundColor: avatarBackground,
} = useAvatar(toRef(props.customer, 'firstName'))

const reviewObserverTarget = ref<null | HTMLElement>(null)

if (props.trackReviewImpression)
  useTrackReviewImpression(reviewObserverTarget, {
    userComment: props,
    isTranslated,
  })

watch(
  () => props.id,
  () => {
    if (props.trackReviewImpression) {
      useTrackReviewImpression(reviewObserverTarget, {
        userComment: props,
        isTranslated,
      })
    }
  },
)

function isInternalLink(link: Link): link is LinkInternal {
  return 'name' in link
}

const productLink = computed(() => {
  if (!isTranslatable) {
    return props.product.link
  }

  const { market } = useMarketplace()

  const locale = toBcp47Locale(market.defaultLocale)

  if (typeof props.product.link === 'string') {
    return props.product.link.replace(/[a-z]{2}-[a-z]{2}/, locale)
  }

  if (isInternalLink(props.product.link)) {
    const { name, params } = props.product.link

    return { name, params: { ...params, locale } }
  }

  return props.product.link
})
</script>
