export default {
  reviewsControllerNextReview: {
    id: 'reviews_controller_next_review',
    defaultMessage: 'Next review',
  },
  reviewsControllerPreviousReview: {
    id: 'reviews_controller_prev_review',
    defaultMessage: 'Previous review',
  },
  reviewsControllerTitle: {
    id: 'reviews_controller_title',
    defaultMessage: 'Review {current}/{max}',
  },
} as const
